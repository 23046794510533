import { LitElement, html } from 'lit-element';
import { renderElements } from '../../elements';

export function __addActiveIf() {
  return classMap({ disabled: true });
}

export const renderFooter = (data) => data.elements
? html`
<footer class="${data.styles||''}">
${renderElements(data.elements)}
</footer>
`
: null


//${renderElements(data.elements)}
