import { LitElement, html, css } from 'lit-element';
 import { App } from '../../app-render/template/app/index.js';
import * as Styles from '../../_styles/global-styles';

export class AppShell extends LitElement {
  static get properties() {
    return {
      Render: { type : Object },
      appData: { type: Object },
      footer: { type: Object },
      header: { type: Object },
      menus: { type: String },
      action: { type: String },
      components: { type : Array },
    };
  }

  constructor() {
    super();
    this.Render= {};
    this.appData= {};
    this.footer = {};
    this.header = {};
    this.menus={};
    this.action= '';
    this.components= [];
  }


  render() {
    const { appData, header, footer, menus, action, Render } = this;

    return html`
<style>

img#menu_user_trigger {width: 32px;
height: 32px;border-radius: 50%;}

* > a:not(:first-child):not(:last-child){margin:0 .25rem}
* > a:last-child{margin-left:.25rem}
* > a:first-child{margin-right:.25rem}

a,a:active,a:focus,a:hover,a:link,a:visited{transition:color .15s ease-in}
a{text-decoration:none;color:rgba(0,0,0,.6)}
a:hover{color:#4e6cef}a:focus,a[active]{color:#455ede;text-decoration:underline}



/* LOCAL */
app-router{display:block;width:100%;min-height:100vh}

header .row{height:60px;}
header nav {text-transform: uppercase;letter-spacing: .05em;font-weight: 600;}

footer{border-top:1px solid rgba(0,0,0,.05)}
footer #menu_footer_2{border-top:1px solid rgba(0,0,0,.07); padding: .5em 0}




@media screen and (min-width:40em){#menu_main_drawer_trigger{display:none}}
@media screen and (max-width:40em){#menu_header_1{display:none} }




</style>






    ${appData.version
    ? html `
    ${App.renderHeader(header, this)}


    <app-router
      appName="${appData.appName}"
      appLanguage="${appData.appLanguage}"
      @routeUpdated="${(e) => this.updateRoute(e)}"
      @ViewDataLoaded="${(e) => this.updateViewData(e)}"
      >
    </app-router>


    ${App.renderFooter(footer, this)}



    <app-sidebar id="sidebar_1" id="modal" ?active .data > slot </app-sidebar>
    <app-modal id="modal" ?active .data > </app-modal>
    <app-toast id="toast" ?active .data > </app-toast>
    <app-message id="notification" ?active .data > </app-message>






    `
    : null
    }
    `;
  }




  static get styles() {

    return [ Styles.component, Styles.flex, Styles.dynamic, Styles.colors  ]
  }



  firstUpdated(changedProperties) {
        const app= this.getAttribute('appName');
        const language= this.getAttribute('appLanguage');
        const url= window.location.hostname==='localhost' ? '//localhost:5001/vanilla-666/us-central1' : '//us-central1-vanilla-666.cloudfunctions.net';
        const action= '/app/init/';
        // Get app data
        const req= url + action + app+'/'+language;

        fetch(req)
        .then(res => res.json())
        .then(json => {
          json.data.appName= app;
          json.data.appLanguage= language;
          this.appData=json.data;
        })

        import ('../../app-router/app-router.js')
        import ('../../app-notify/app-modal.js')
        import('../../app-notify/app-toast.js')
        import ('../../app-notify/app-message.js')
        import('../../app-dropdown/app-dropdown.js')
        import('../../app-sidebar/app-sidebar.js')
  }

  updateViewData(e) {
    this.appData.toasts={...this.appData.toasts, ...e.detail.toasts};
    this.appData.modals={...this.appData.modals, ...e.detail.modals};
    this.appData.notifications={...this.appData.notifications, ...e.detail.notifications};
  }

  updateRoute(e) {
     this.header= this.appData.headers[e.detail.header] || {};
     this.footer= !e.detail.footer ? this.appData.footers['default'] : this.appData.footers[e.detail.footer];
     this.cta= !e.detail.cta ? this.appData.ctas['default'] : this.appData.ctas[e.detail.cta];
  }
}
